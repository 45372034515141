import * as types from "../action.types";
import { BASE_URL } from "../../app.config";

export function getPayments(country) {
  return async (dispatch) => {
    dispatch({ type: types.GET_PAYMENTS_LOADING });

    try {
      const response = await fetch(`${BASE_URL}/ops/payments/${country}`, {
        method: "GET",
      });

      const result = await response.json();

      if (result.success) {
        dispatch({
          type: types.GET_PAYMENTS_SUCCESS,
          payload: result.payments,
        });
      } else {
        dispatch({
          type: types.GET_PAYMENTS_FAILED,
          payload: result,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
}
