import React from "react";
import {
  FormControlLabel,
  makeStyles,
  Switch,
  withStyles,
} from "@material-ui/core";

const PaymentItem = ({ payment, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.item} style={{ flex: 3 }}>
        <p className={classes.text}>{payment.name}</p>
      </div>

      <div className={classes.item} style={{ flex: 3 }}>
        {payment.mobile && (
          <FormControlLabel
            control={
              <MySwitch
                checked={payment.mobile.status}
                onChange={() => {
                  payment.mobile.status = !payment.mobile.status;
                  onChange(payment);
                }}
              />
            }
          />
        )}
      </div>
      <div className={classes.item} style={{ flex: 3 }}>
        {payment.card && (
          <FormControlLabel
            control={
              <MySwitch
                checked={payment.card.status}
                onChange={() => {
                  payment.card.status = !payment.card.status;
                  onChange(payment);
                }}
              />
            }
          />
        )}
      </div>
      <div className={classes.item} style={{ flex: 3 }}>
        {payment.link && (
          <FormControlLabel
            control={
              <MySwitch
                checked={payment.link.status}
                onChange={() => {
                  payment.link.status = !payment.link.status;
                  onChange(payment);
                }}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 5,
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #bfbfbf",

    "&:hover": {
      backgroundColor: "#EBFCFF",
    },

    "& .edit": {
      display: "none",
    },

    "&:hover .edit": {
      display: "block",
    },
  },

  btn: {
    borderRadius: 20,
    textTransform: "none",
    fontSize: 12,
    backgroundColor: "#f0f0f0",
    color: "#000",

    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  },

  item: {
    flex: 1,
  },

  text: {
    fontSize: 15,
    margin: "7px 0px",
  },
}));

const MySwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#099c40",
    },
    "&$checked + $track": {
      backgroundColor: "#099c40",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default PaymentItem;
