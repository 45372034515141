import * as types from "../action.types";

const initState = {
  payments: [],
};

export const paymentsReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_PAYMENTS_SUCCESS:
      return { payments: action.payload };

    default:
      return state;
  }
};
