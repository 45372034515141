import {
  disableUserReducer,
  resetPasswordReducer,
  userReducer,
  usersReducer,
} from "./users.reducer";
import authReducer from "./auth.reducer";
import { zoneComplexReducer } from "./zone.complex.reducer";
import { customerReducer, customersReducer } from "./customers.reducer";
import { complexesReducer } from "./complexes.reducer";
import { remoteConfigReducer } from "./remote.config";
import { menuReducer } from "./menu.reducer";
import { collectionComplexesReducer } from "./collection.complexes.reducer";
import { stockReducer } from "./stock.reducer";
import { paymentsReducer } from "./payments.reducer";

function rootReducer(state = {}, action) {
  return {
    auth: authReducer(state.auth, action),
    users: usersReducer(state.users, action),
    user: userReducer(state.user, action),
    customers: customersReducer(state.customers, action),
    customer: customerReducer(state.customer, action),
    password_reset: resetPasswordReducer(state.password_reset, action),
    user_disable: disableUserReducer(state.user_disable, action),
    zones_complexes: zoneComplexReducer(state.zones_complexes, action),
    collection_complexes: collectionComplexesReducer(
      state.collection_complexes,
      action
    ),
    complexes: complexesReducer(state.complexes, action),
    remote: remoteConfigReducer(state.remote, action),
    menu: menuReducer(state.menu, action),
    stocks: stockReducer(state.stocks, action),
    payments: paymentsReducer(state.payments, action),
  };
}
export default rootReducer;
