import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Layout from "../components/Layout";
import { checkToken } from "../utils/check.token";
import CollectionComplexes from "./collectionComplexes";
import Complexes from "./complexes";
import CustomerAlerts from "./customerAlerts";
import CustomerDetails from "./customerDetails";
import Customers from "./customers";
import Dashboard from "./dashboard";
import Login from "./login";
import Products from "./products";
import StockOuts from "./stockOuts";
import Users from "./users";
import Voucher from "./voucher";
import Settings from "./settings";
import Payments from "./payments";

const isAuth = Boolean(localStorage.getItem("jwTtoken"));

const AuthRoute = ({ component: Component, ...rest }) => {
  const r_state = useSelector((state) => state);

  if (isAuth) {
    if (r_state.auth.user.role === "site") {
      window.location.replace("/stock-outs");
    } else {
      window.location.replace("/delivery-complexes");
    }
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
    );
  }
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.auth?.user);

  useEffect(() => {
    checkToken();
  }, []);

  if (!isAuth) {
    window.location.replace("/login");
  } else {
    if (user.role === "agent" && rest.path === "/users") {
      window.location.replace("/not-allowed");
    } else if (user.role === "site" && rest.path !== "/stock-outs") {
      window.location.replace("/not-allowed");
    } else if (
      user.role !== "admin" &&
      user.role !== "manager" &&
      rest.path === "/settings"
    ) {
      window.location.replace("/not-allowed");
    } else {
      return (
        <>
          <Route
            {...rest}
            render={(props) => {
              return (
                <Layout {...props} {...rest}>
                  <Component {...props} {...rest} />
                </Layout>
              );
            }}
          />
        </>
      );
    }
  }
};

const Routes = (props) => {
  const r_state = useSelector((state) => state);

  return (
    <Router>
      <Switch>
        {r_state.auth?.user?.role === "site" ? (
          <Redirect from="/" exact to="/stock-outs" />
        ) : (
          <Redirect from="/" exact to="/delivery-complexes" />
        )}

        <AuthRoute path="/login" component={Login} />

        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/dashboard"
          component={Dashboard}
        />

        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/delivery-complexes"
          component={Complexes}
        />
        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/payments"
          component={Payments}
        />

        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/stock-outs"
          component={StockOuts}
        />

        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/collection-complexes"
          component={CollectionComplexes}
        />

        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/users"
          component={Users}
        />

        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/customers"
          component={Customers}
        />

        <Redirect from="/customer" exact to="/customers" />

        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/customer-alerts"
          component={CustomerAlerts}
        />
        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/customer/:phone"
          component={CustomerDetails}
        />

        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/voucher"
          component={Voucher}
        />

        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/products"
          component={Products}
        />

        <PrivateRoute
          user_mgt={props.user_mgt}
          country={props.country}
          path="/settings"
          component={Settings}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
