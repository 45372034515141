import { Box, Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PaymentItem from "../components/payments/paymentItem";
import { BASE_URL } from "../app.config";
import { useDispatch, useSelector } from "react-redux";
import { GET_PAYMENTS_SUCCESS } from "../redux/action.types";
import Loader from "react-loader-spinner";

const headings = ["Provider", "Mobile", "Card", "Link"];

const Payments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const remote = useSelector((state) => state.remote);
  const { user } = useSelector((state) => state.auth);
  const { payments: rps } = useSelector((state) => state.payments);

  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (rps) {
      setPayments([...rps]);
    }
  }, [rps]);

  const onSubmit = async () => {
    if (!isLoading) {
      setIsLoading(true);

      const response = await fetch(`${BASE_URL}/ops/payments/bulk`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          payments,
          user: user.email,
          country: remote.country.country_code.toLowerCase(),
        }),
      });

      const result = await response.json();

      if (result.success) {
        dispatch({
          type: GET_PAYMENTS_SUCCESS,
          payload: payments,
        });
      }

      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className={classes.heading}>
        <h2 className={classes.headingTitle}>Payments</h2>
      </div>
      <div className={classes.main}>
        <div style={{ width: "50%" }}>
          <div className={classes.header} spacing={2}>
            {headings.map((title, index) => (
              <div
                key={index}
                className={classes.headerItem}
                style={{
                  flex: 3,
                  display: "inline-flex",
                }}
              >
                <p className={classes.title}>{title}</p>
              </div>
            ))}
          </div>
          <div className={classes.content}>
            {payments.map((payment, index) => (
              <PaymentItem
                key={index}
                payment={payment}
                onChange={(p) => {
                  payments[index] = p;
                  setPayments([...payments]);
                }}
              />
            ))}
          </div>

          <Box sx={{ textAlign: "end", marginTop: 10 }}>
            <Button
              onClick={onSubmit}
              variant="contained"
              className={classes.btn}
              color="secondary"
            >
              {isLoading ? (
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: -2,
                  }}
                >
                  <Loader type="Oval" color="#fff" width="20" height="20" />
                </div>
              ) : (
                "Save"
              )}
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    width: "100%",
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },

  headingTitle: {
    marginTop: 1,
    marginBottom: 1,
    marginLeft: 12,
  },

  main: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
  },

  notchedOutline: {
    border: 0,
  },

  header: {
    backgroundColor: "#000",
    padding: 5,
    display: "flex",
    flexDirection: "row",
  },

  headerItem: {
    flex: 1,
    "& .sort": {
      display: "none",
    },
    "&:hover .sort": {
      display: "block",
      color: "#82817f",
    },
  },
  title: {
    color: "#fff",
    margin: 0,
    fontWeight: 500,
    fontSize: 16,
  },

  content: {
    backgroundColor: "#fff",
    padding: 7,
    paddingTop: 0,
    miHeight: "65vh",
  },

  label: {
    marginTop: 15,
    marginBottom: 3,
    marginLeft: 3,
    fontSize: 15,
    fontWeight: 600,
  },

  btn: {
    textTransform: "none",
  },
}));

export default Payments;
